<template>
 <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('incentDashboard.farm_title') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row class="justify-content-md-center card-fmms">
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-1 ">
                        <router-link :to="{ path: '/incentive-grant-service/farm-machinery/configuration/supplier-info' }">
                            <div class="total_card_wrapper">
                                <div class="content">
                                    <h5 class="title">{{ $t('incentDashboard.totalSupplier') }}</h5>
                                    <h2 class="count count_1">{{ $n(total_suppliers) }}</h2>
                                </div>
                            </div>
                        </router-link>
                      </b-card>
                    </b-col>
                    <b-col sm="3">
                      <b-card class="iq-card shadow_card card-1">
                        <router-link :to="{ path: '/incentive-grant-service/farm-machinery/application-management/approved-application' }">
                            <div class="total_card_wrapper">
                            <div class="content">
                                <h5 class="title">{{ $t('incentDashboard.totalDistributed') }}</h5>
                                <h2 class="count count_1">{{ $n(total_distributed) }}</h2>
                            </div>
                            </div>
                        </router-link>
                      </b-card>
                    </b-col>
                  </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmMachine } from '../../api/routes'

export default {
    name: 'IrrigationDashboard',
    components: {
    },
    data () {
      return {
        loadingState: true,
        total_suppliers: 0,
        total_distributed: 0
      }
    },
    computed: {
    },
    created () {
        this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
        async loadData () {
            const response = await RestApi.getData(incentiveGrantServiceBaseUrl, farmMachine)
            if (response.success) {
                this.total_suppliers = response.total_suppliers
                this.total_distributed = response.total_distributed
            } else {
                this.total_suppliers = 0
                this.total_distributed = 0
            }
            this.loadingState = false
        }
    }
}
</script>
<style>
.card-fmms .shadow_card{
    border: 1px solid #6509cf!important;
    border-bottom: 4px solid #6509cf;
}
.card-fmms .shadow_card .count{
    color: #6509cf;
    font-weight: 600;
}
</style>
