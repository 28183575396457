export const list = '/list'
export const store = '/store'
export const update = '/update'
export const toggleStatus = '/toggle-status'
export const destroy = '/delete'

const dashboard = '/dashboard'
export const allOrgApi = dashboard + '/index'
export const bsri = dashboard + '/bsri'
export const dae = dashboard + '/dae'
export const barc = dashboard + '/barc'
export const farmMachine = dashboard + '/fm'

export const notificationReceivedList = '/notification-received/list'
